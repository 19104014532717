var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        { staticClass: "p-0 m-0 grid-card" },
        [
          _c("CCardHeader", [
            _c("span", { staticClass: "header-title" }, [_vm._v("お知らせ")]),
            _c(
              "div",
              { staticClass: "card-header-actions" },
              [
                _vm.deviceWarningCount > 0
                  ? _c(
                      "CButton",
                      {
                        attrs: { color: "success", size: "sm" },
                        on: { click: _vm.sendDeviceWarning }
                      },
                      [_vm._v("機器警報画面へ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _c(
            "CCardBody",
            { staticClass: "p-0 m-0" },
            [
              _c("MyBeeSearchBar", {
                attrs: {
                  "curr-page": _vm.pagingParams.currPage,
                  "per-page": _vm.pagingParams.perPage,
                  "per-page-list": _vm.perPageList,
                  pages: _vm.pages,
                  "search-bar-place-holder": _vm.searchBarPlaceHolder,
                  keyword: _vm.pagingParams.keyword
                },
                on: {
                  goodPaging: _vm.goodPaging,
                  changePage: _vm.changePage,
                  devSearch: _vm.devSearch,
                  "update:currPage": function($event) {
                    return _vm.$set(_vm.pagingParams, "currPage", $event)
                  },
                  "update:curr-page": function($event) {
                    return _vm.$set(_vm.pagingParams, "currPage", $event)
                  },
                  "update:perPage": function($event) {
                    return _vm.$set(_vm.pagingParams, "perPage", $event)
                  },
                  "update:per-page": function($event) {
                    return _vm.$set(_vm.pagingParams, "perPage", $event)
                  },
                  "update:keyword": function($event) {
                    return _vm.$set(_vm.pagingParams, "keyword", $event)
                  }
                }
              }),
              _c("BeeGridTable", {
                ref: "bee",
                attrs: {
                  stripe: "",
                  border: "",
                  showFilter: false,
                  columns: _vm.columns,
                  loading: _vm.isLoading,
                  data: _vm.rows,
                  showPager: false,
                  maxHeight: _vm.gridCardHeight - 20,
                  "no-data-text": "データがありません。",
                  "no-filtered-data-text": "",
                  context: _vm.context,
                  "span-method": _vm.customSpan,
                  showVerticalScrollBar: false,
                  showHorizontalScrollBar: false,
                  "row-class-name": _vm.clickable
                },
                on: { "on-row-click": _vm.getNotice },
                scopedSlots: _vm._u([
                  {
                    key: "readed",
                    fn: function(ref) {
                      var row = ref.row
                      var index = ref.index
                      return [
                        row.readed == true
                          ? _c("span", [_vm._v("既読")])
                          : _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("未読")
                            ])
                      ]
                    }
                  },
                  {
                    key: "title",
                    fn: function(ref) {
                      var row = ref.row
                      var index = ref.index
                      return [
                        _c("div", { staticClass: "text-left" }, [
                          _vm._v(" " + _vm._s(row.title) + " ")
                        ])
                      ]
                    }
                  },
                  {
                    key: "fromDate",
                    fn: function(ref) {
                      var row = ref.row
                      var index = ref.index
                      return [
                        _c("div", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(row.fromDate))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        {
          staticClass: "p-0 m-0 mt-2 form-card",
          staticStyle: { "min-height": "330px", "border-top": "solid 2px #000" }
        },
        [
          _c("CCardHeader", [
            _c("span", { staticClass: "header-title" }, [
              _vm._v("お知らせ内容 ")
            ])
          ]),
          _c(
            "CCardBody",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.notice.id,
                  expression: "notice.id"
                }
              ],
              staticClass: "p-0 m-0"
            },
            [
              _c(
                "CCard",
                { staticClass: "p-0 m-0" },
                [
                  _c(
                    "CCardBody",
                    { staticClass: "p-0" },
                    [
                      _c(
                        "CRow",
                        { staticClass: "p-0 m-0" },
                        [
                          _c("CCol", [
                            _c(
                              "div",
                              {
                                staticClass: "p-1",
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "18px"
                                }
                              },
                              [_vm._v(_vm._s(_vm.notice.title))]
                            ),
                            _c("div", { staticStyle: { height: "250px" } }, [
                              _c(
                                "pre",
                                {
                                  staticClass: "py-1 pl-2",
                                  staticStyle: {
                                    height: "220px",
                                    "overflow-y": "auto"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.notice.content))]
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }