<template>
  <div>
    <CCard class="p-0 m-0 grid-card" >
      <CCardHeader>
        <span class="header-title">お知らせ</span>

        <div class="card-header-actions">
          <CButton v-if="deviceWarningCount > 0" color="success" size="sm" @click="sendDeviceWarning">機器警報画面へ</CButton>
        </div>

      </CCardHeader>
      <CCardBody class="p-0 m-0">
        <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                        :curr-page.sync="pagingParams.currPage"
                        :per-page.sync="pagingParams.perPage"
                        :per-page-list="perPageList"
                        :pages="pages"
                        :search-bar-place-holder="searchBarPlaceHolder"
                        :keyword.sync="pagingParams.keyword"></MyBeeSearchBar>
        <BeeGridTable
            ref="bee"
            stripe
            border
            :showFilter="false"
            :columns="columns"
            :loading="isLoading"
            :data="rows"
            :showPager="false"
            :maxHeight="gridCardHeight -20"
            no-data-text="データがありません。"
            no-filtered-data-text=""
            :context="context"
            :span-method="customSpan"
            :showVerticalScrollBar="false"
            :showHorizontalScrollBar="false"
            @on-row-click="getNotice"
            :row-class-name="clickable"
        >
          <!-- HEADER ソート可能にするために 必要-->
          <!-- CELL ソート可能にするために 必要-->
          <template slot-scope="{ row,index }" slot="readed">
            <span v-if="row.readed == true">既読</span>
            <span v-else style="color: red;">未読</span>
          </template>
          <template slot-scope="{ row,index }" slot="title">
            <div class="text-left">
              {{ row.title}}
            </div>
          </template>

          <template slot-scope="{ row,index }" slot="fromDate">
            <div class="text-left">{{row.fromDate}}</div>
          </template>



        </BeeGridTable>
      </CCardBody>
    </CCard>
    <CCard class="p-0 m-0 mt-2 form-card" style="min-height: 330px;border-top:solid 2px #000">
      <CCardHeader>
        <span class="header-title">お知らせ内容
<!--        {{gridCardHeight}} windowHeight={{this.windowHeight}} - headerHeight={{this.headerHeight}} - formCardHeight={{this.formCardHeight}}-->
        </span>
      </CCardHeader>
      <CCardBody class="p-0 m-0" v-show="notice.id">
        <CCard class="p-0 m-0">
          <CCardBody class="p-0" >
            <CRow class="p-0 m-0">
              <CCol>
                <div style="font-weight: bold;font-size: 18px" class="p-1">{{ notice.title }}</div>
                <div style="height:250px;">
                  <pre class="py-1 pl-2" style="height:220px;overflow-y:auto;">{{ notice.content }}</pre>
                </div>
              </CCol>
            </CRow>

          </CCardBody>
          <!--              <CCardFooter v-else>お知らせはありません。</CCardFooter>-->
        </CCard>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";
export default {
        name: 'NoticeList',
        components:{MyBeeHeader,MyBeeSearchBar},
        mixins:[BeeMix],
        data() {
            return {
                // perPage: 10,
                searchBarPlaceHolder:"件名で検索",
                columns: [
                  // {key:"createrName", title: '作成者',  minWidth: 100,align: 'left',resizable: false,  },
                  {key:"readed", title: '既読／未読',slot:"readed",  width: 100,align: 'center',resizable: false,  },
                  {key:"fromDate", title: '掲示日',slot:"fromDate",  width: 240,align: 'center',resizable: false,  },
                  {key:"title", title: '件名',slot:"title",  minWidth: 100,align: 'center',resizable: false,  },
                ],
                notice: {},
                deviceWarningCount:0
            };
        },
        methods:  {
            goodPaging(isPageChange) {
                if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
                this.isLoading = true;
                this.axios.post('/api/notice/paging', {pagingForm:this.pagingParams}, {headers: {'Content-Type': 'application/json'}}).then(response => {
                    this.totalRecords = response.data.result.totalElements;
                    this.rows = response.data.result.content;
                    //ページ件数
                    this.pages = response.data.result.totalPages;
                    this.topLeft();

                }).catch(error => console.log(error)
                ).finally(() => this.isLoading = false);
            },
            getNotice:function (data,index) {
              this.axios.get(`/api/notice/get/${data.id}`).then(response => {

                this.notice = response.data.result;
                this.rows[index].readed = true;
                this.handleResize();
              }).catch(error => console.log(error)
              ).finally(() => this.isLoading = false);
            },getDeviceWarningCount:function () {
              if( this.isProvider() || this.isAdministrator() || this.isRegistrator()){
                this.axios.get(`/api/device/warning_count`).then(response => {
                  this.deviceWarningCount = response.data.result;
                }).catch(error => console.log(error)
                ).finally(() => this.isLoading = false);
              }
            },
            sendDeviceWarning:function(){
              this.$router.push({path: "/pages/device/healthinfo"});
            }
        },mounted() {
            this.getDeviceWarningCount();
        }
  }
</script>

